var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: { columns: _vm.columns, "data-source": _vm.schedule },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function(text) {
          return _c("div", {}, [_vm._v(_vm._s(_vm.nTime(text)))])
        }
      },
      {
        key: "sessionLength",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.floatToHour(text)) + " Hours\n  ")
          ])
        }
      },
      {
        key: "dob",
        fn: function(text) {
          return _c("div", {}, [_vm._v(_vm._s(_vm.nFormat(text)))])
        }
      },
      {
        key: "created_at",
        fn: function(text) {
          return _c("div", {}, [_vm._v(_vm._s(_vm.nFormat(text)))])
        }
      },
      {
        key: "phone",
        fn: function(text) {
          return _c(
            "div",
            {},
            [text ? [_vm._v("+353 " + _vm._s(text))] : [_vm._v("N/A")]],
            2
          )
        }
      },
      {
        key: "status",
        fn: function(text) {
          return _c(
            "div",
            { staticClass: "status_data" },
            [
              text === "accepted"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        width: "135px",
                        "line-height": "33px",
                        height: "33px",
                        "text-align": "center"
                      },
                      attrs: { color: "#27ae60" }
                    },
                    [_vm._v("Active")]
                  )
                : _vm._e(),
              text === "invited"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        width: "135px",
                        "line-height": "33px",
                        height: "33px",
                        "text-align": "center"
                      },
                      attrs: { color: "#f39c12" }
                    },
                    [_vm._v("Invite Pending")]
                  )
                : _vm._e(),
              text === "requested"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        width: "135px",
                        "line-height": "33px",
                        height: "33px",
                        "text-align": "center"
                      },
                      attrs: { color: "#8e44ad" }
                    },
                    [_vm._v("Has Requested to Join")]
                  )
                : _vm._e(),
              text === "archived"
                ? _c("a-tag", { attrs: { color: "#d35400" } }, [
                    _vm._v("Archived")
                  ])
                : _vm._e(),
              text === "declined"
                ? _c("a-tag", { attrs: { color: "#c0392b" } }, [
                    _vm._v("Declined Invitation")
                  ])
                : _vm._e()
            ],
            1
          )
        }
      },
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              _c(
                "router-link",
                { attrs: { to: "/club/member/" + record.user_id } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px"
                      },
                      attrs: { size: "small", type: "primary" }
                    },
                    [_c("a-icon", { attrs: { type: "user" } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "" } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-red",
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small", type: "danger" }
                    },
                    [_c("a-icon", { attrs: { type: "delete" } })],
                    1
                  )
                ],
                1
              ),
              record.status === "accepted"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(record.id, "archived")
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "download" } })],
                    1
                  )
                : _vm._e(),
              record.status !== "accepted" &&
              record.status !== "invited" &&
              record.status !== "declined" &&
              record.status !== "requested"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-cyan",
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(record.id, "accepted")
                        }
                      }
                    },
                    [_vm._v("Make Active")]
                  )
                : _vm._e(),
              record.status === "requested"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-cyan",
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(record.id, "accepted")
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "check" } })],
                    1
                  )
                : _vm._e(),
              record.status === "invited"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-red",
                      staticStyle: {
                        "margin-bottom": "0",
                        height: "32px",
                        "font-size": "20px",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(record.id, "cancelled")
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "close" } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }